.checkbox-group {
  margin-top: 20px;

  .pd-agreement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .pd-agreement__label {
    line-height: 25px;
  }

  .pd-agreement__checkbox {
    top: 2px;
  }

  .text-input {
    margin-top: 25px;
  }
}
