.scale {
  overflow-x: auto;

  .radio-group-wrapper-level {
    display: flex;
    flex-direction: column;
  
    .radio-group-list {
      display: flex;
    }
  
    .radio-group__label {
      padding-right: 25px;
      padding-left: 0;
      font-size: 14px;
      line-height: 1.25;
  
      &_text {
        width: 17px;
      }
    }
  
    .radio-group__radio {
      top: 0;
      left: 16px;
    }
  
    .radio-group__radio-indicator {
      top: 0;
      width: 18px;
      height: 18px;
      margin-right: 0;
      margin-left: 10px;
  
      &.last {
        left: 8px;
      }
    }
  }
}
