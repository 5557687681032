.header {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 300px;
  margin-bottom: 40px;
  background-color: #EF3124;
  // background: linear-gradient(37deg,  #fff 0%, #6fcacb 15%, red, #F47920 85%, #F0be32 100%);

  // @media only screen and (max-width: '768px') {
  //   height: 90px;
  // }

  @media only screen and (max-width: '768px') {
    height: 200px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 265px;
    width: 30px;
    height: 46px;
    transform: translateY(-50%);
    background-image: url('../../img/logo_1.svg');
    background-size: 100%;
    background-repeat: no-repeat;

    @media only screen and (max-width: '1024px') {
      left: 125px;
    }

    @media only screen and (max-width: '768px') {
      left: 45px;
    }

    @media only screen and (max-width: '425px') {
      content: none;
    }
  }

  &::before {
    content: 'Образовательный курс Alfa Factory';
    position: absolute;
    top: calc(50% + 5px);
    right: 188px;
    width: 175px;
    height: 46px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 16px;
    line-height: 19px;
    // background-image: url('../../img/x5tech.svg');
    // background-size: 100%;
    // background-repeat: no-repeat;

    @media only screen and (max-width: '1024px') {
      right: 60px;
    }

    @media only screen and (max-width: '768px') {
      right: 25px;
    }

    @media only screen and (max-width: '425px') {
      content: none;
    }
  }

  &_container {
    width: 100%;
    height: 100%;
    background-image: url('../../img/Group_1.jpg');
    background-position: center;
    background-size: 850px 300px;
    background-repeat: no-repeat;

    @media only screen and (max-width: '768px') {
      background-size: 650px 200px;
    }

    .header__direction {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      padding: 0 20px 14px;
      box-sizing: border-box;

      &-title {
        font-size: 17px;
        line-height: 1;
        color: #000;
        font-weight: normal;
        letter-spacing: 0.17px;
  
        @media only screen and (max-width: '768px') {
          font-size: 14px;
        }
      }

      &-count {
        font-size: 17px;
        line-height: 1;
        color: #000;
        font-weight: normal;
        letter-spacing: 0.17px;
  
        @media only screen and (max-width: '768px') {
          font-size: 14px;
        }
      }
    }
    
  }
}

.test {
  &__header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 14px 20px;
    background-image: url('../../img/abcamp.svg');
    background-size: 100%;
  }

  &__header-direction {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 !important;
    padding: 14px 20px;
    background-color: #55B435;
    box-sizing: border-box;

    .test__header-title {
      font-size: 17px;
      line-height: 1;
      color: #000;
      font-weight: normal;
      letter-spacing: 0.17px;

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }
    }

    .test__header-count {
      font-size: 17px;
      line-height: 1;
      color: #000;
      font-weight: normal;
      letter-spacing: 0.17px;

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }
    }
  }

  &__header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 7px;
    font-size: 32px;
    line-height: 1;
    color: #fff;

    &-start {
      width: 26px;
      height: 32px;
      background-image: url('../../img/a@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-finish {
      width: 26px;
      height: 32px;
      background-image: url('../../img/b@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      animation: hidden linear 0.5s infinite;
    }
  }

  &__progress-wrapper {
    width: 100%;
    height: 8px;
    background-color: #fff;

    .test__progress {
      height: 8px;
      background-color: #55B435;
      opacity: 0.5;
      transition: all linear 0.3s;
    }
  }
}