.textarea {
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  textarea {
    resize: vertical;
    min-height: 140px;
    padding: 10px 20px !important;
    font-size: 14px !important;
    line-height: 1.5 !important;
    color: #000 !important;
    background-color: transparent !important;
    border: 1px solid #E2E2E2 !important;
    border-bottom: 3px solid #E2E2E2 !important;
    appearance: none !important;
    outline: none !important;
    transition: all ease 0.3s !important;
    box-sizing: border-box !important;
    font-family: 'Roboto', 'sans-serif' !important;

    &:focus {
      border-bottom: 3px solid #EF3124 !important;
    }
  }

  span {
    margin-top: 10px;
    font-size: 14px;
    color: #c9c9c9;
  }
}
