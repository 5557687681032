.test-tilda-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C0D1B2;
  height: 100%;
  min-height: 100vh;

  @media only screen and (max-width: 1240px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 767px) {
    padding: 0 10px;
  }

  .test-tilda--blabla {
    width: 50% !important;
    min-width: 300px !important;

    @media only screen and (max-width: 1240px) {
      max-width: 700px !important;
      width: 100% !important;
      font-size: 16px !important;
    }

    @media only screen and (max-width: 1240px) {
      font-size: 12px !important;
    }

    .test__link-button-wrapper {
      display: flex !important;
      justify-content: center !important;
      margin: 0 !important;

      a {
        width: 270px !important;
        height: 52px !important;
        border-radius: 83px !important;
        font-size: 16px !important;
        line-height: 16px !important;
        letter-spacing: 0.1em !important;

        &::after {
          content: none !important;
        }
      }
    }
  }
}

.test-tilda {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 120px auto;

  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    max-width: 700px;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    max-width: none !important;
    margin: 60px 10px;
  }

  .test-tilda__right {
    display: flex;
    flex-direction: column;
    width: 50%;
    max-width: 517px;
    margin-right: 100px;

    @media only screen and (max-width: 1240px) {
      width: 100%;
      max-width: none;
      margin-right: 0;
      margin-bottom: 35px;
    }

    p {
      font-style: normal;
      font-weight: bold;
      font-size: 60px;
      line-height: 68px;
      color: #000000;
      margin: 0;
      margin-bottom: 45px;

      @media only screen and (max-width: 1240px) {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 30px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 25px;
        line-height: 28px;
      }
    }

    .test-tilda-volni {
      width: 227px;
      height: 31px;
      background-image: url('../../img/wawe1.svg');
      background-size: 100%;
      margin-bottom: 6px;

      @media only screen and (max-width: 1240px) {
        width: 175px;
        background-repeat: no-repeat;
      }

      @media only screen and (max-width: 767px) {
        width: 140px;
        height: 20px;
      }
    }
  }

  .test-tilda__question-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    max-width: 580px;

    @media only screen and (max-width: 1240px) {
      width: 100%;
      max-width: none ;
    }
  }

  .test-tilda__question {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    &-fields {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 35px;

      @media only screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 5px;
      }

      .test-tilda__field {
        width: 100%;
        max-width: 270px;

        @media only screen and (max-width: 1240px) {
          width: calc(50% - 15px) !important;
          max-width: none;
          display: flex;
          flex-direction: column;
          margin-bottom: 23px;
        }

        @media only screen and (max-width: 767px) {
          width: 100% !important;
        }

        .test__question-title {
          margin-top: 0 !important;
          font-size: 14px !important;
          line-height: 14px !important;
          margin-bottom: 14px !important;
        }

        .test__question-title1 {
          margin-top: 0 !important;
          font-size: 14px !important;
          line-height: 14px !important;
          margin-bottom: 14px !important;
          font-weight: normal;
        }

        .text-input {
          width: 100% !important;
          max-width: 270px !important;

          @media only screen and (max-width: 1240px) {
            max-width: none !important;
          }
        }

        input {
          border-radius: 0px 26px 26px 26px !important;
        }

        .autocomplete-input {
          input {
            background-color: #C0D1B2 !important;
          }
        }
      }

      &-pda {
        display: flex;
        justify-content: space-between;
        margin-top: 35px !important;

        @media only screen and (max-width: 767px) {
          flex-direction: column;
        }

        .test-tilda__question-field-pda {
          width: 280px;
          margin-right: 30px;

          @media only screen and (max-width: 1240px) {
            width: calc(50% - 15px) !important;
            margin-right: 0 !important;
          }

          @media only screen and (max-width: 767px) {
            width: 100% !important;
            margin-bottom: 30px;
          }

          > p {
            margin: 0 !important;
            padding: 0 !important;
            font-size: 10px !important;
            line-height: 10px !important;
          }

          .pd-agreement {
            margin: 0 !important;
            margin-top: 14px !important;

            .pd-agreement__checkbox {
              border-color: #000;
              width: 14px !important;
              height: 14px !important;

              &::after {
                top: -4px !important;
              }
            }

            .pd-agreement__label {
              font-size: 10px !important;
              line-height: 10px !important;
            }
          }
        }

        .test-tilda__question-field-button {
          width: 270px;

          @media only screen and (max-width: 1240px) {
            width: calc(50% - 15px) !important;
          }

          @media only screen and (max-width: 767px) {
            width: 100% !important;
          }

          .test__btn-block {
            padding: 0 !important;
            margin: 0 !important;

            .test__btn-block {
              align-items: flex-start !important;
              justify-content: flex-start !important;

              @media only screen and (max-width: 1240px) {
                width: 100% !important;
              }

              .test__link-button-wrapper {
                margin: 0 !important;

                @media only screen and (max-width: 1240px) {
                  width: 100%;
                }

                button {
                  width: 270px !important;
                  height: 52px !important;
                  border-radius: 83px !important;
                  font-size: 16px !important;
                  line-height: 16px !important;
                  letter-spacing: 0.1em !important;

                  @media only screen and (max-width: 1240px) {
                    width: 100% !important;
                  }

                  &::after {
                    content: none !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    &-title {
      margin-top: 50px;
      margin-bottom: 25px;
      padding-right: 10px;
      padding-left: 0;
      font-size: 24px;
      line-height: 1;
      color: #000;
      font-weight: 300;
      // color: #ef3124;

      &__link {
        display: flex;
        align-items: center;
        color: #EF3124;
      }

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }

      a {
        color: #EF3124;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &-subtitle {
      margin-top: 0;
      padding-right: 10px;
      font-size: 15px;
      font-weight: 100;
      line-height: 1.5;
      color: #c9c9c9;
    }
  }

  .special-label {
    display: none;
  }
}

.test__btn-block {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 97px;
  width: 100%;
  max-width: 850px;
  margin: 0 auto 50px;
  padding: 0 20px;
  // background-image: url('../../img/cuboid_ab.svg');
  background-size: calc(100% - 40px) 97px;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  .test__btn-prev {
    position: relative;
    right: 0;
    width: 45px;
    height: 51px;
    margin-top: 20px;
    margin-left: 15px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    transition: all linear 0.2s;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/active-arrow-forward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      opacity: 1;
      transition: all linear 0.2s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/arrow-backward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all linear 0.2s;
    }

    &_disabled {
      &::after {
        opacity: 0;
        transition: all linear 0.2s;
        cursor: default;
      }
  
      &::before {
        opacity: 1;
        transition: all linear 0.2s;
        cursor: default;
      }
    }
  }

  .test__btn-next {
    position: relative;
    right: 0;
    width: 45px;
    height: 51px;
    margin-top: 20px;
    margin-right: 15px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    transition: all linear 0.2s;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/active-arrow-forward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 1;
      transition: all linear 0.2s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/arrow-backward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      opacity: 0;
      transition: all linear 0.2s;
    }

    &_disabled {
      &::after {
        opacity: 0;
        transition: all linear 0.2s;
        cursor: default;
      }
  
      &::before {
        opacity: 1;
        transition: all linear 0.2s;
        cursor: default;
      }
    }
  }
}

// .pd-agreement {
//   position: relative;
//   display: flex;
//   align-items: center;
//   margin-top: 50px;
//   margin-bottom: 30px;

//   &__label {
//     position: relative;
//     display: block;
//     padding-left: calc(12px + 12px);
//     font-size: 13px;
//     line-height: 1.45;
//     color: #000;
//     word-break: break-word;
//     cursor: pointer;

//     span {
//       text-decoration: underline;
//       cursor: pointer;
//       color: #EF3124;
//     }

//     .popup-content {
//       max-width: 700px !important;
//       min-width: 300px !important;
//     }

//     .modal > .close {
//       cursor: pointer;
//       position: absolute;
//       display: block;
//       padding: 4px 5px;
//       line-height: 20px;
//       right: -10px;
//       top: -10px;
//       font-size: 24px;
//       background: #ffffff;
//       border-radius: 18px;
//       border: 1px solid #cfcece;
//     }
//   }

//   &__input {
//     position: absolute;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     opacity: 0;
//     width: 12px;
//     height: 12px;
//     margin: 0;
//     cursor: pointer;
//   }

//   &__checkbox {
//     position: absolute;
//     top: 1px;
//     left: 0;
//     display: inline-block;
//     vertical-align: middle;
//     width: 12px;
//     height: 12px;
//     margin-right: 10px;
//     border: 1px solid #e2e2e2;
//     box-sizing: border-box;
//     opacity: 0.6;
//     transition: all 0.2s;

//     &::after {
//       content: '';
//       position: absolute;
//       top: -6px;
//       left: 5px;
//       display: none;
//       width: 4px;
//       height: 12px;
//       border: solid #000;
//       border-width: 0 2px 2px 0;
//       transform: rotate(45deg);
//     }

//     &.checked {
//       opacity: 1;

//       &::after {
//         content: '';
//         position: absolute;
//         top: -6px;
//         left: 5px;
//         display: block;
//         width: 4px;
//         height: 12px;
//         border: 2px solid #EF3124;
//         border-width: 0 2px 2px 0;
//         transform: rotate(45deg);
//       }
//     }
//   }
// }

// .test__link-button {
//   position: relative;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 80px;
//   height: 80px;
//   border-radius: 50%;
//   background-color: #EF3124;
//   color: #fff;
//   text-align: center;
//   text-decoration: none;
//   border: 0;
//   cursor: pointer;
//   font-size: 12px;
//   line-height: 15px;
//   text-transform: uppercase;

//   &::after {
//     content: '';
//     position: absolute;
//     top: 48px;
//     left: 32px;
//     width: 17px;
//     height: 10px;
//     background-image: url('../../img/arrow.png');
//     background-size: 17px 10px;
//   }

//   &:hover {
//     background-color: #EF3124;
//   }

//   &-wrapper {
//     display: flex;
//     justify-content: flex-end;
//     align-items: center;
//     margin-top: 50px;
//   }
// }

.pd-info {
  padding: 25px;
}

@keyframes hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.telegra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  p {
    font-size: 17px;
    color: #000000;
    margin: 0;
  }

  @media only screen and (max-width: '768px') {
    font-size: 18px;
  }

  a {
    display: flex;
    align-items: center;
    width: 49px;
    height: 49px;
    margin-top: 20px;
    outline: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.flag-dropdown {
  display: none;
}
