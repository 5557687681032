.text-input {
  position: relative;
  width: 100%;

  input {
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 10px 20px;
    font-size: 14px;
    line-height: 32px;
    color: #000;
    background-color: transparent;
    border: 1px solid #E2E2E2;
    border-bottom: 3px solid #E2E2E2;
    appearance: none;
    outline: none;
    transition: all ease 0.3s;
    box-sizing: border-box;

    &:focus {
      border-bottom: 3px solid #EF3124;
    }
  }

  .form-control {
    width: 100% !important;
    height: 50px !important;
    margin: 0 !important;
    padding: 10px 20px !important;
    font-size: 14px !important;
    line-height: 32px !important;
    color: #000 !important;
    background-color: transparent !important;
    border: 1px solid #E2E2E2 !important;
    border-bottom: 3px solid #E2E2E2 !important;
    border-radius: 0 !important;
    appearance: none !important;
    outline: none !important;
    transition: all ease 0.3s !important;
    box-sizing: border-box !important;

    &:focus {
      border-bottom: 3px solid #EF3124 !important;
    }
  }

  span {
    font-size: 12px;
    color: #ef3124;
    position: relative;
    top: 5px;
  }
}
