.test-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    width: 100vw;
  }
}

.test {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .test__question {
    width: 100%;
    max-width: 850px;
    padding: 20px 20px 65px;
    box-sizing: border-box;

    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 55px;
    }

    &-title {
      margin-top: 50px;
      margin-bottom: 25px;
      padding-right: 10px;
      padding-left: 0;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #000;
      font-weight: bold;
      // color: #ef3124;

      &__link {
        display: flex;
        align-items: center;
        color: #EF3124;
      }

      @media only screen and (max-width: '768px') {
        font-size: 14px;
      }

      a {
        color: #EF3124;
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &-subtitle {
      margin-top: 0;
      padding-right: 10px;
      font-size: 15px;
      font-weight: 100;
      line-height: 1.5;
      color: #c9c9c9;
    }
  }

  .special-label {
    display: none;
  }
}

.test__btn-block {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 97px;
  width: 100%;
  max-width: 850px;
  margin: 0 auto 50px;
  padding: 0 20px;
  // background-image: url('../../img/cuboid_ab.svg');
  background-size: calc(100% - 40px) 97px;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  .test__btn-prev {
    position: relative;
    right: 0;
    width: 45px;
    height: 51px;
    margin-top: 20px;
    margin-left: 15px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    transition: all linear 0.2s;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/active-arrow-forward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      opacity: 1;
      transition: all linear 0.2s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/arrow-backward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 0;
      transition: all linear 0.2s;
    }

    &_disabled {
      &::after {
        opacity: 0;
        transition: all linear 0.2s;
        cursor: default;
      }
  
      &::before {
        opacity: 1;
        transition: all linear 0.2s;
        cursor: default;
      }
    }
  }

  .test__btn-next {
    position: relative;
    right: 0;
    width: 45px;
    height: 51px;
    margin-top: 20px;
    margin-right: 15px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    transition: all linear 0.2s;
    outline: none;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/active-arrow-forward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      opacity: 1;
      transition: all linear 0.2s;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('../../img/arrow-backward@3x.png');
      background-size: 100%;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      opacity: 0;
      transition: all linear 0.2s;
    }

    &_disabled {
      &::after {
        opacity: 0;
        transition: all linear 0.2s;
        cursor: default;
      }
  
      &::before {
        opacity: 1;
        transition: all linear 0.2s;
        cursor: default;
      }
    }
  }
}

.pd-agreement {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;

  &__label {
    position: relative;
    display: block;
    padding-left: calc(12px + 12px);
    font-size: 14px;
    line-height: 1.25;
    color: #000;
    word-break: break-word;
    cursor: pointer;

    span {
      text-decoration: underline;
      cursor: pointer;
      color: #EF3124;
    }

    .popup-content {
      max-width: 700px !important;
      min-width: 300px !important;
    }

    .modal > .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 4px 5px;
      line-height: 20px;
      right: -10px;
      top: -10px;
      font-size: 24px;
      background: #ffffff;
      border-radius: 18px;
      border: 1px solid #cfcece;
    }
  }

  &__input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    opacity: 0;
    width: 12px;
    height: 12px;
    margin: 0;
    cursor: pointer;
  }

  &__checkbox {
    position: absolute;
    top: 1px;
    left: 0;
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    border: 1px solid #e2e2e2;
    box-sizing: border-box;
    border-radius: 2px;
    opacity: 0.6;
    transition: all 0.2s;

    &::after {
      content: '';
      position: absolute;
      top: -6px;
      left: 5px;
      display: none;
      width: 4px;
      height: 12px;
      border: solid #000;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    &.checked {
      opacity: 1;
      background-color: #EF3124;
      border: 1px solid #EF3124;

      &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 4px;
        display: block;
        width: 3px;
        height: 7px;
        border: 1px solid #fff;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);
      }
    }
  }
}

.test__link-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 60px;
  background-color: #EF3124;
  color: #fff;
  text-align: center;
  text-decoration: none;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  &:hover {
    background-color: #EF3124;
  }

  &-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
  }
}

.pd-info {
  padding: 25px;
}

@keyframes hidden {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.telegra {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  p {
    font-size: 17px;
    color: #000000;
    margin: 0;
  }

  @media only screen and (max-width: '768px') {
    font-size: 18px;
  }

  a {
    display: flex;
    align-items: center;
    width: 49px;
    height: 49px;
    margin-top: 20px;
    outline: none;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.flag-dropdown {
  display: none;
}
